import React, {Dispatch, ReactElement, SetStateAction} from "react";
import styles from "../screens/configGenerator/config.module.scss";
import {AppConfig, AppConfigs} from "../interfaces/AppConfig";
import {
  getAdditionalKeys,
  getElementTypes,
  getElementMoves,
  getEventList, getFunctionTypes,
  getKnownVars,
  getMandatoryKeys, getParamTypes, getVarGroup, getCodeTypes
} from "./getEvents";
import {RenameField} from '../components/renameField/RenameField';
import {retrieveKey} from './helpers';
import {InputNumber, InputString, InputDropdown} from '../components/inputs/Input';
import {FunctionNames} from "../interfaces/Constants";

const _ = require('lodash');

type ParentProps = {
  getPermissionWrapper: (e: string) => ({ read?: boolean; write?: boolean });
  design: AppConfig | undefined;
  designs: AppConfigs | undefined;
  designFilled: AppConfig | undefined;
  setter: (baseKey: string, val: any) => void;
  getPermissionType: (e: string, type: string) => (string);
  missingURLs: string[];
  setMissingURLs: React.Dispatch<React.SetStateAction<string[]>>;
  deleteKey: (path: string) => void;
  designTag: string;
  translate: ((e: string) => string);
  isInstance: boolean;
}

export const genFunctionInputs = (
  functionName: FunctionNames,
  parentProps: ParentProps,
  language: string,
  translate: ((key: string, language: string) => string),
  getPermissionWrapper: ((key: string) => {write?: boolean, read?: boolean}),
  getPermissionType: ((key: string, type: string) => string),
  pageNames: string[],
  colorBG: string,
  setDesign: Dispatch<SetStateAction<AppConfig | undefined>>,
  recalcFilledConfig: (() => void),
  setKey: ((oldKey: string, newKey: any) => void),
  deleteKey: ((elemName: string, key: string) => void),
  deleteElement: ((key: string) => void),
  copyElement: ((key: string) => void),
  pasteElement: (() => {}),
  addNewKeyWrapper: ((e: React.KeyboardEvent<HTMLInputElement>, elemName: string) => void),
  addNewKeyButton: ((key: string, name: string) => void),
  addNewElementWrapper: ((e: React.KeyboardEvent<HTMLInputElement>) => void),
  addNewElementButton: ((key: string) => void),
  design?: AppConfig,
  designFilled?: AppConfig,
  designs?: AppConfigs,
) => {

  const codeTypes = getCodeTypes();
  const mandatoryKeys = getMandatoryKeys();
  const elementTypes = getElementTypes();
  const elementMoves = getElementMoves();
  const renderSwitchSettings = (fieldType: string, funcName: string, index: number, key: string, parentProp: any, placeholder?: string) => {

    switch(fieldType) {
      case 'number':
        return <InputNumber path={`settings.${funcName}.${index}.${key}`} parentProps={parentProp}/>
      case 'string':
        return <InputString path={`settings.${funcName}.${index}.${key}`} placeholder={translate(placeholder ?? '', language)} parentProps={parentProp}/>
      case 'boolean':
        return <InputDropdown
          path={`settings.${funcName}.${index}.${key}`}
          valueList={['true', 'false']}
          shouldTranslate={placeholder}
          defaultVal={placeholder}
          parentProps={parentProps}
        />
    }
  }

  const renderSwitchElem = (fieldType: string, elemName: string, key: string, parentProp: any) => {

    switch(fieldType) {
      case 'number':
        return <InputNumber path={`design.elements.${elemName}.${key}`} parentProps={parentProp}/>
      case 'string':
        return <InputString path={`design.elements.${elemName}.${key}`} parentProps={parentProp}/>
      case 'boolean':
        return <InputDropdown
          path={`design.elements.${elemName}.${key}`}
          valueList={['true', 'false']}
          parentProps={parentProps}
        />
    }
  }


  let rows: (ReactElement | ReactElement[])[] = [];
  switch (functionName) {
    case FunctionNames.genFunctionParameters: {
      rows.push(<tr key={`hr-params`}><td><hr/></td></tr>)
      rows.push(
        <tr className={styles["table-row"]} key={`params-key`}>
          <td>
            {translate('ifKey', language)}
          </td>
          <td/>
          <td>
            <InputString placeholder={translate('ifKey', language)} path={'settings.functionParameters.key'} parentProps={parentProps}/>
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )
      rows.push(
        <tr className={styles["table-row"]} key={`params-group`}>
          <td>
            {translate('ifGroup', language)}
          </td>
          <td/>
          <td>
            <InputDropdown
              path={"settings.functionParameters.group"}
              valueList={getVarGroup()}
              allowOther={true}
              readOnly={!getPermissionWrapper(`settings.functionParameters.group`).write}
              type={getPermissionType(`settings.functionParameters.group`, 'text')}
              shouldTranslate={'ifGroup'}
              defaultVal={'ifGroup'}
              parentProps={parentProps}
            />
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )
      rows.push(
        <tr className={styles["table-row"]} key={`params-if-condition`}>
          <td>
            {translate('ifCondition', language)}
          </td>
          <td/>
          <td>
            <InputDropdown
              path={"settings.functionParameters.condition"}
              valueList={['>', '>=', '=', '<=', '<']}
              allowOther={false}
              readOnly={!getPermissionWrapper(`settings.functionParameters.group`).write}
              type={getPermissionType(`settings.functionParameters.condition`, 'text')}
              shouldTranslate={'ifCondition'}
              defaultVal={'ifCondition'}
              parentProps={parentProps}
            />
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )
      rows.push(
        <tr className={styles["table-row"]} key={`params-if-value`}>
          <td>
            {translate('ifVal', language)}
          </td>
          <td/>
          <td>
            <InputString placeholder={translate('ifVal', language)} path={'settings.functionParameters.value'} parentProps={parentProps}/>
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      );
      break;
    }
    case FunctionNames.genSwitchParameters: {
      rows.push(<tr key={`hr-params`}><td><hr/></td></tr>)

      const switchParams = design?.settings.switchParameters ?? []

      switchParams.forEach((val, i) => {
        rows.push(
          <tr className={styles["table-row"]} key={`${i}-switch-value`}>
            <td>
              {translate('switchParam', language)} {i}
            </td>
            <InputString placeholder={translate('switchConditionKey', language)} path={`settings.switchParameters.${i}.switchVal`}
                         parentProps={parentProps}/>
            <td>

              <InputDropdown
                path={`settings.switchParameters.${i}.switchTarget`}
                valueList={['previousScreen', ...pageNames]}
                allowOther={false}
                readOnly={!getPermissionWrapper(`settings.switchParameters.${i}.switchTarget`).write}
                type={getPermissionType(`settings.switchParameters.${i}.switchTarget`, 'text')}
                shouldTranslate={'switchTarget'}
                defaultVal={'switchTarget'}
                parentProps={parentProps}
              />
            </td>
            <td>
              <input
                type={"button"}
                value={translate('deleteSwitch', language)}
                onClick={() => {
                  const perm = getPermissionWrapper(`settings.switchParameters`).write;
                  if (!perm) {
                    return;
                  }
                  if (!design) {
                    return;
                  }
                  const designClone: any = _.cloneDeep(design);
                  const settings = designClone?.settings?.switchParameters ?? [];
                  settings.splice(i, 1);
                  designClone!.settings.switchParameters = settings;
                  setDesign(designClone);
                  recalcFilledConfig();

                }}
              />
            </td>
            <td/>
            <td/>
            <td/>
          </tr>
        )
      })

      rows.push(
        <tr className={styles["table-row"]} key={`$new-switch-value`}>
          <td>
            <input
              type={"button"}
              value={translate('switchNewKey', language)}
              onClick={() => {
                const perm = getPermissionWrapper(`settings.switchParameters`).write;
                if (!perm) {
                  return;
                }
                if (!design) {
                  return;
                }
                const designClone: any = _.cloneDeep(design);
                const settings = designClone?.settings?.switchParameters ?? [];
                settings.push({});
                designClone!.settings.switchParameters = settings;
                setDesign(designClone);
                recalcFilledConfig();

              }}
            />
          </td>
          <td/>
          <td/>
          <td/>
          <td/>
          <td/>
        </tr>
      )



      rows.push(
        <tr className={styles["table-row"]} key={`params-key`}>
          <td>
            {translate('ifKey', language)}
          </td>
          <td/>
          <td>
            <InputString placeholder={translate('switchConditionKey', language)} path={'settings.resKey'} parentProps={parentProps}/>
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )
      rows.push(
        <tr className={styles["table-row"]} key={`params-group`}>
          <td>
            {translate('ifGroup', language)}
          </td>
          <td/>
          <td>
            <InputDropdown
              path={"settings.resGroup"}
              valueList={getVarGroup()}
              allowOther={true}
              readOnly={!getPermissionWrapper(`settings.resGroup`).write}
              type={getPermissionType(`settings.resGroup`, 'text')}
              shouldTranslate={'switchConditionGroup'}
              defaultVal={'switchConditionGroup'}
              parentProps={parentProps}
            />
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )
      break;
    }
    case FunctionNames.genCopyParameters: {
      rows.push(<tr key={`hr-copy`}><td><hr/></td></tr>)
      const copyParams = design?.settings.copyParameters ?? []

      copyParams.forEach((val, i) => {
        rows.push(
          <tr className={styles["table-row"]} key={`${i}-copy-value`}>
            <td>
              {translate('copyParam', language)} {i}
            </td>
            <td>
              <InputDropdown
                path={`settings.copyParameters.${i}.originKey`}
                valueList={getKnownVars(design?.settings?.copyParameters?.[i]?.originGroup ?? "")}
                allowOther={true}
                readOnly={!getPermissionWrapper(`settings.copyParameters.${i}.originKey`).write}
                type={getPermissionType(`settings.copyParameters.${i}.originKey`, 'text')}
                shouldTranslate={'copyOriginKey'}
                defaultVal={'copyOriginKey'}
                parentProps={parentProps}
              />
            </td>
            <td>
              <InputDropdown
                path={`settings.copyParameters.${i}.originGroup`}
                valueList={getVarGroup()}
                allowOther={true}
                readOnly={!getPermissionWrapper(`settings.copyParameters.${i}.originGroup`).write}
                type={getPermissionType(`settings.copyParameters.${i}.originGroup`, 'text')}
                shouldTranslate={'copyOriginGroup'}
                defaultVal={'copyOriginGroup'}
                parentProps={parentProps}
              />
            </td>
            <td>
              <InputDropdown
                path={`settings.copyParameters.${i}.targetGroup`}
                valueList={getVarGroup()}
                allowOther={true}
                readOnly={!getPermissionWrapper(`settings.copyParameters.${i}.originGroup`).write}
                type={getPermissionType(`settings.copyParameters.${i}.originGroup`, 'text')}
                shouldTranslate={'copyTargetGroup'}
                defaultVal={'copyTargetGroup'}
                parentProps={parentProps}
              />
            </td>
            <td>
              <InputString placeholder={translate('copyTargetKey', language)} path={`settings.copyParameters.${i}.targetKey`} parentProps={parentProps}/>
            </td>
            <td>
              <input
                type={"button"}
                value={translate('copyDelete', language)}
                onClick={() => {
                  const perm = getPermissionWrapper(`design.settings.copyParameters`).write;
                  if (!perm) {
                    return;
                  }
                  if (!design) {
                    return;
                  }
                  const designClone: any = _.cloneDeep(design);
                  const settings = designClone?.settings?.copyParameters ?? [];
                  settings.splice(i, 1);
                  designClone!.settings.copyParameters = settings;
                  setDesign(designClone);
                  recalcFilledConfig();

                }}
              />
            </td>
          </tr>
        )
      })
      rows.push(
        <tr className={styles["table-row"]} key={`$new-copy-value`}>
          <td>
            <input
              type={"button"}
              value={translate('copyNewKey', language)}
              onClick={() => {
                const perm = getPermissionWrapper(`design.settings.copyParameters`).write;
                if (!perm) {
                  return;
                }
                if (!design) {
                  return;
                }
                const designClone: any = _.cloneDeep(design);
                const settings = designClone?.settings?.copyParameters ?? [];
                settings.push({});
                designClone!.settings.copyParameters = settings;
                setDesign(designClone);
                recalcFilledConfig();

              }}
            />
          </td>
          <td/>
          <td/>
          <td/>
          <td/>
          <td/>
        </tr>
      )

      break;
    }
    case FunctionNames.genSetParameters: {
      rows.push(<tr key={`hr-copy`}><td><hr/></td></tr>)
      const setParams = design?.settings.setParameters ?? []

      setParams.forEach((val, i) => {
        rows.push(
          <tr className={styles["table-row"]} key={`${i}-copy-value`}>
            <td>
              {translate('setParam', language)} {i}
            </td>
            <td>
              {
                val?.newValueType === 'function'
                  ?
                  <InputDropdown
                    path={`settings.setParameters.${i}.newValue`}
                    valueList={getFunctionTypes()[0]}
                    allowOther={false}
                    readOnly={!getPermissionWrapper(`settings.setParameters.${i}.newValue`).write}
                    type={getPermissionType(`settings.setParameters.${i}.newValue`, 'text')}
                    shouldTranslate={'setNewValue'}
                    defaultVal={'setNewValue'}
                    parentProps={parentProps}
                  />
                  :
                  renderSwitchSettings(val?.newValueType ?? "string", 'setParameters', i, 'newValue', parentProps, 'setNewValue')
              }
            </td>
            <td>
              <InputDropdown
                path={`settings.setParameters.${i}.newValueType`}
                valueList={getParamTypes()}
                allowOther={false}
                readOnly={!getPermissionWrapper(`settings.setParameters.${i}.newValueType`).write}
                type={getPermissionType(`settings.setParameters.${i}.newValueType`, 'text')}
                shouldTranslate={'setNewValueType'}
                defaultVal={'setNewValueType'}
                parentProps={parentProps}
              />
            </td>
            <td>
              <InputDropdown
                path={`settings.setParameters.${i}.targetGroup`}
                valueList={getVarGroup()}
                allowOther={true}
                readOnly={!getPermissionWrapper(`settings.setParameters.${i}.targetGroup`).write}
                type={getPermissionType(`settings.setParameters.${i}.targetGroup`, 'text')}
                shouldTranslate={'setTargetGroup'}
                defaultVal={'setTargetGroup'}
                parentProps={parentProps}
              />
            </td>
            <td>
              <InputString placeholder={translate('setTargetKey', language)} path={`settings.setParameters.${i}.targetKey`} parentProps={parentProps}/>
            </td>
            <td>
              <input
                type={"button"}
                value={translate('setDelete', language)}
                onClick={() => {
                  const perm = getPermissionWrapper(`design.settings.setParameters`).write;
                  if (!perm) {
                    return;
                  }
                  if (!design) {
                    return;
                  }
                  const designClone: any = _.cloneDeep(design);
                  const settings = designClone?.settings?.setParameters ?? [];
                  settings.splice(i, 1);
                  designClone!.settings.setParameters = settings;
                  setDesign(designClone);
                  recalcFilledConfig();

                }}
              />
            </td>
          </tr>
        )
        if (val?.newValueType === 'function' && getFunctionTypes()[1].includes(val?.newValue?.toString() ?? "")) {
          rows.push(
            <tr className={styles["table-row"]} key={`${i}-copy-value`}>
              <td>
                {translate('setParam2', language)} {i}
              </td>
              <td>
                <InputString placeholder={translate('setInputKey1', language)} path={`settings.setParameters.${i}.inputKey1`} parentProps={parentProps}/>
              </td>
              <td>
                <InputDropdown
                  path={`settings.setParameters.${i}.inputGroup1`}
                  valueList={getVarGroup()}
                  allowOther={true}
                  readOnly={!getPermissionWrapper(`settings.setParameters.${i}.inputGroup1`).write}
                  type={getPermissionType(`settings.setParameters.${i}.inputGroup1`, 'text')}
                  shouldTranslate={'setInputGroup1'}
                  defaultVal={'setInputGroup1'}
                  parentProps={parentProps}
                />
              </td>
              <td>
                <InputString placeholder={translate('setInputKey2', language)} path={`settings.setParameters.${i}.inputKey2`} parentProps={parentProps}/>
              </td>
              <td>
                <InputDropdown
                  path={`settings.setParameters.${i}.inputGroup2`}
                  valueList={getVarGroup()}
                  allowOther={true}
                  readOnly={!getPermissionWrapper(`settings.setParameters.${i}.inputGroup2`).write}
                  type={getPermissionType(`settings.setParameters.${i}.inputGroup2`, 'text')}
                  shouldTranslate={'setInputGroup2'}
                  defaultVal={'setInputGroup2'}
                  parentProps={parentProps}
                />
              </td>
              <td>
                <input
                  type={"button"}
                  value={translate('setDelete', language)}
                  onClick={() => {
                    const perm = getPermissionWrapper(`design.settings.setParameters`).write;
                    if (!perm) {
                      return;
                    }
                    if (!design) {
                      return;
                    }
                    const designClone: any = _.cloneDeep(design);
                    const settings = designClone?.settings?.setParameters ?? [];
                    settings.splice(i, 1);
                    designClone!.settings.setParameters = settings;
                    setDesign(designClone);
                    recalcFilledConfig();

                  }}
                />
              </td>
            </tr>
          )

        }


        if (val?.newValueType === 'function' && getFunctionTypes()[2].includes(val?.newValue?.toString() ?? "")) {
          rows.push(
            <tr className={styles["table-row"]} key={`${i}-copy-value`}>
              <td>
                {translate('setParam2', language)} {i}
              </td>
              <td>
                <InputNumber path={`settings.setParameters.${i}.min`} parentProps={parentProps}/>
              </td>
              <td>
                <InputNumber path={`settings.setParameters.${i}.max`} parentProps={parentProps}/>
              </td>
              <td>
                <InputDropdown
                  path={`settings.setParameters.${i}.isInt`}
                  valueList={['true', 'false']}
                  readOnly={!getPermissionWrapper(`settings.setParameters.${i}.isInt`).write}
                  type={getPermissionType(`settings.setParameters.${i}.isInt`, 'text')}
                  booleanDefault={'true'}
                  shouldTranslate={'switchConditionGroup'}
                  defaultVal={'switchConditionGroup'}
                  parentProps={parentProps}
                />
              </td>
              <td>
              </td>
              <td/>
            </tr>
          )

        }


        if (val?.newValueType === 'function' && getFunctionTypes()[3].includes(val?.newValue?.toString() ?? "")) {
          rows.push(
            <tr className={styles["table-row"]} key={`${i}-copy-value`}>
              <td>
                {translate('setParam2', language)} {i}
              </td>
              <td>
                <InputString placeholder={translate('setInputKey1', language)}
                             path={`settings.setParameters.${i}.inputKey1`} parentProps={parentProps}/>
              </td>
              <td>
                <InputDropdown
                  path={`settings.setParameters.${i}.inputGroup1`}
                  valueList={getVarGroup()}
                  allowOther={true}
                  readOnly={!getPermissionWrapper(`settings.setParameters.${i}.inputGroup1`).write}
                  type={getPermissionType(`settings.setParameters.${i}.inputGroup1`, 'text')}
                  shouldTranslate={'setInputGroup1'}
                  defaultVal={'setInputGroup1'}
                  parentProps={parentProps}
                />
              </td>
              <td>
                <InputNumber path={`settings.setParameters.${i}.accuracy`} parentProps={parentProps}/>
              </td>
              <td>
              </td>
              <td/>
            </tr>
          )

        }
      })
      rows.push(
        <tr className={styles["table-row"]} key={`$new-copy-value`}>
          <td>
            <input
              type={"button"}
              value={translate('setNewKey', language)}
              onClick={() => {
                const perm = getPermissionWrapper(`design.settings.setParameters`).write;
                if (!perm) {
                  return;
                }
                if (!design) {
                  return;
                }
                const designClone: any = _.cloneDeep(design);
                const settings = designClone?.settings?.setParameters ?? [];
                settings.push({});
                designClone!.settings.setParameters = settings;
                setDesign(designClone);
                recalcFilledConfig();

              }}
            />
          </td>
          <td/>
          <td/>
          <td/>
          <td/>
          <td/>
        </tr>
      )
      break;
    }
    case FunctionNames.genSplitStringParameters: {
      rows.push(<tr key={`hr-copy`}><td><hr/></td></tr>)
      const splitParams = design?.settings.splitParameters ?? []

      rows.push(
        <tr className={styles["table-row"]} key={`split-values`}>
          <td>
            {translate('splitParam', language)}
          </td>
          <td>
            <InputDropdown
              path={`settings.splitParameters.originKey`}
              valueList={getKnownVars(design?.settings?.splitParameters?.originGroup ?? "")}
              allowOther={true}
              readOnly={!getPermissionWrapper(`settings.splitParameters.originKey`).write}
              type={getPermissionType(`settings.splitParameters.originKey`, 'text')}
              shouldTranslate={'splitOriginKey'}
              defaultVal={'splitOriginKey'}
              parentProps={parentProps}
            />
          </td>
          <td>
            <InputDropdown
              path={`settings.splitParameters.originGroup`}
              valueList={getVarGroup()}
              allowOther={true}
              readOnly={!getPermissionWrapper(`settings.splitParameters.originGroup`).write}
              type={getPermissionType(`settings.splitParameters.originGroup`, 'text')}
              shouldTranslate={'splitOriginGroup'}
              defaultVal={'splitOriginGroup'}
              parentProps={parentProps}
            />
          </td>
          <td>
            <InputDropdown
              path={`settings.splitParameters.targetGroup`}
              valueList={getVarGroup()}
              allowOther={true}
                readOnly={!getPermissionWrapper(`settings.splitParameters.targetGroup`).write}
              type={getPermissionType(`settings.splitParameters.targetGroup`, 'text')}
              shouldTranslate={'splitTargetGroup'}
              defaultVal={'splitTargetGroup'}
              parentProps={parentProps}
            />
          </td>
          <td>
            <InputString placeholder={translate('splitTargetKey', language)} path={`settings.splitParameters.targetKey`} parentProps={parentProps}/>
          </td>
        </tr>
      )

      rows.push(
        <tr className={styles["table-row"]} key={`split-character`}>
          <td>
            {translate('splitCharacter', language)}
          </td>
          <td>
            <InputString placeholder={translate('splitCharacter', language)} path={`settings.splitParameters.splitCharacter`} parentProps={parentProps}/>
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )

      break;
    }
    case FunctionNames.genPushToResultArrayParameters: {
      rows.push(<tr key={`hr-params`}><td><hr/></td></tr>)
      rows.push(
        <tr className={styles["table-row"]} key={`params-key`}>
          <td>
            {translate('pushToResultArrayParams', language)}
          </td>
          <td>
            {translate('resGroup', language)}
          </td>
          <td>
            <InputString placeholder={translate('resGroup', language)} path={'settings.resGroup'} parentProps={parentProps}/>
          </td>
          <td>
            {translate('resKey', language)}
          </td>
          <td>
            <InputString placeholder={translate('resKey', language)} path={'settings.resKey'} parentProps={parentProps}/>
          </td>
          <td/>
        </tr>
      )
      break;
    }
    case FunctionNames.genCheckInternetParameters: {
      rows.push(<tr key={`hr-params`}><td><hr/></td></tr>)
      rows.push(
        <tr className={styles["table-row"]} key={`params-key`}>
          <td>
            {translate('pingURL', language)}
          </td>
          <td/>
          <td>
            <InputString placeholder={translate('pingURL', language)} path={'settings.pingURL'} parentProps={parentProps}/>
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )
      break;
    }
    case FunctionNames.genGetParameters: {
      rows.push(<tr key={`hr-params`}><td><hr/></td></tr>)
      rows.push(
        <tr className={styles["table-row"]} key={`params-1-key`}>
          <td>
            {translate('getURL', language)}
          </td>
          <td/>
          <td>
            <InputString placeholder={translate('getURL', language)} path={'settings.getURL'} parentProps={parentProps}/>
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )
      rows.push(
        <tr className={styles["table-row"]} key={`params-2-key`}>
          <td>
            {translate('getURLParams', language)}
          </td>
          <td>
            {translate('getGroup', language)}
          </td>
          <td>
            <InputString placeholder={translate('getGroup', language)} path={'settings.getGroup'} parentProps={parentProps}/>
          </td>
          <td>
            {translate('getKey', language)}
          </td>
          <td>
            <InputString placeholder={translate('getKey', language)} path={'settings.getKey'} parentProps={parentProps}/>
          </td>
          <td/>
        </tr>
      )
      break;
    }
    case FunctionNames.genResParameters: {
      rows.push(<tr key={`hr-params`}><td><hr/></td></tr>)
      rows.push(
        <tr className={styles["table-row"]} key={`params-key`}>
          <td>
            {translate('resURLParams', language)}
          </td>
          <td>
            {translate('resGroup', language)}
          </td>
          <td>
            <InputString placeholder={translate('resGroup', language)} path={'settings.resGroup'} parentProps={parentProps}/>
          </td>
          <td>
            {translate('resKey', language)}
          </td>
          <td>
            <InputString placeholder={translate('resKey', language)} path={'settings.resKey'} parentProps={parentProps}/>
          </td>
          <td/>
        </tr>
      )
      rows.push(
        <tr className={styles["table-row"]} key={`params-key`}>
          <td>
            {translate('resURLSettings', language)}
          </td>
          <td>
            {translate('resURLResultOnly', language)}
          </td>
          <td>
            <InputDropdown
              path={`settings.partialResult`}
              valueList={['true', 'false']}
              readOnly={!getPermissionWrapper(`settings.partialResult`).write}
              type={getPermissionType(`settings.partialResult`, 'text')}
              booleanDefault={'false'}
              shouldTranslate={'partialResult'}
              defaultVal={'partialResult'}
              parentProps={parentProps}
            />
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )
      break;
    }
    case FunctionNames.genQRParameters: {
      rows.push(<tr key={`hr-params`}>
        <td>
          <hr/>
        </td>
      </tr>)
      rows.push(
        <tr className={styles["table-row"]} key={`params-1-key`}>
          <td>
            {translate('qrType', language)}
          </td>
          <td/>
          <td>
            <InputDropdown
              path={`settings.codeType`}
              valueList={codeTypes}
              readOnly={!getPermissionWrapper(`settings.codeType`).write}
              type={getPermissionType(`settings.codeType`, 'text')}
              shouldTranslate={'qrCodeType'}
              defaultVal={'qrCodeType'}
              parentProps={parentProps}
            />
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      );

      rows.push(
        <tr className={styles["table-row"]} key={`params-2-key`}>
          <td>
            {translate('qrEnableFlip', language)}
          </td>
          <td/>
          <td>
            <InputDropdown
              path={`settings.codeEnableFlip`}
              valueList={['true', 'false']}
              readOnly={!getPermissionWrapper(`settings.codeEnableFlip`).write}
              type={getPermissionType(`settings.codeEnableFlip`, 'text')}
              booleanDefault={'false'}
              shouldTranslate={'qrCodeFlip'}
              defaultVal={'qrCodeFlip'}
              parentProps={parentProps}
            />
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      );

      rows.push(
        <tr className={styles["table-row"]} key={`params-3-key`}>
          <td>
            {translate('qrFrontCamera', language)}
          </td>
          <td/>
          <td>
            <InputDropdown
              path={`settings.codeFrontCamera`}
              valueList={['true', 'false']}
              readOnly={!getPermissionWrapper(`settings.codeFrontCamera`).write}
              type={getPermissionType(`settings.codeCamera`, 'text')}
              booleanDefault={'false'}
              shouldTranslate={'qrCodeFront'}
              defaultVal={'qrCodeFront'}
              parentProps={parentProps}
            />
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      );

      rows.push(
        <tr className={styles["table-row"]} key={`params-4-key`}>
          <td>
            {translate('resURLParams', language)}
          </td>
          <td>
            {translate('resGroup', language)}
          </td>
          <td>
            <InputString placeholder={translate('resGroup', language)} path={'settings.resGroup'} parentProps={parentProps}/>
          </td>
          <td>
            {translate('resKey', language)}
          </td>
          <td>
            <InputString placeholder={translate('resKey', language)} path={'settings.resKey'} parentProps={parentProps}/>
          </td>
          <td/>
        </tr>
      )
      break;
    }
    case FunctionNames.genPostParameters: {
      rows.push(<tr key={`hr-params`}>
        <td>
          <hr/>
        </td>
      </tr>)
      rows.push(
        <tr className={styles["table-row"]} key={`params-key`}>
          <td>
            {translate('postURL', language)}
          </td>
          <td/>
          <td>
            <InputString placeholder={translate('postURL', language)} path={'settings.postURL'} parentProps={parentProps}/>
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )
      rows.push(
        <tr className={styles["table-row"]} key={`params-key`}>
          <td>
            {translate('postGroup', language)}
          </td>
          <td/>
          <td>
            <InputString placeholder={translate('postGroup', language)} path={'settings.postGroup'} parentProps={parentProps}/>
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )
      rows.push(
        <tr className={styles["table-row"]} key={`params-key`}>
          <td>
            {translate('postKey', language)}
          </td>
          <td/>
          <td>
            <InputString placeholder={translate('postKey', language)} path={'settings.postKey'} parentProps={parentProps}/>
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      )
      break;
    }
    case FunctionNames.genDownloadParameters: {
      rows.push(<tr key={`hr-params`}>
        <td>
          <hr/>
        </td>
      </tr>)
      rows.push(
        <tr className={styles["table-row"]} key={`params-key`}>
          <td>
            {translate('getURLData', language)}
          </td>
          <td>
            {translate('getURL', language)}
          </td>
          <td>
            <InputString placeholder={translate('getURL', language)} path={'settings.getURL'} parentProps={parentProps}/>
          </td>
          <td>
            {translate('getKey', language)}
          </td>
          <td>
            <InputString placeholder={translate('getKey', language)} path={'settings.getKey'} parentProps={parentProps}/>
          </td>
          <td/>
        </tr>
      )
      break;
    }
    case FunctionNames.genParseJSONParameters: {
      rows.push(<tr key={`hr-params`}><td><hr/></td></tr>)
      rows.push(
        <tr className={styles["table-row"]} key={`params-2-key`}>
          <td>
            {translate('getJSONParams', language)}
          </td>
          <td>
            {translate('getGroup', language)}
          </td>
          <td>
            <InputString placeholder={translate('getGroup', language)} path={'settings.getGroup'} parentProps={parentProps}/>
          </td>
          <td>
            {translate('getKey', language)}
          </td>
          <td>
            <InputString placeholder={translate('getKey', language)} path={'settings.getKey'} parentProps={parentProps}/>
          </td>
          <td/>
        </tr>
      )
      rows.push(
        <tr className={styles["table-row"]} key={`params-2-key`}>
          <td>
            {translate('resJSONParams', language)}
          </td>
          <td>
            {translate('resGroup', language)}
          </td>
          <td>
            <InputString placeholder={translate('resGroup', language)} path={'settings.resGroup'} parentProps={parentProps}/>
          </td>
          <td>
            {translate('resKey', language)}
          </td>
          <td>
            <InputString placeholder={translate('resKey', language)} path={'settings.resKey'} parentProps={parentProps}/>
          </td>
          <td/>
        </tr>
      )
      break;
    }
    case FunctionNames.genEvents: {
      if (!design?.settings.callFunction){
        return [];
      }
      const events = getEventList(design?.settings.callFunction);
      rows = [];
      if (events.length === 0) {
        return [];
      }
      rows.push(<tr key={`hr-${events}`}><td><hr/></td></tr>)
      const eventElements = events.map((ev) => {
        return <tr className={styles["table-row"]} key={ev}>
          <td>
            {translate(ev, language)}
          </td>
          <td/>
          <td>
            <InputDropdown
              path={`settings.eventTargets.${ev}`}
              valueList={['previousScreen', ...pageNames]}
              readOnly={!getPermissionWrapper(`settings.eventTargets.${ev}`).write}
              type={getPermissionType(`settings.eventTargets.${ev}`, 'text')}
              parentProps={parentProps}
            />
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      })
      rows = [...rows, ...eventElements];
      break;
    }
    case FunctionNames.genElements: {
      const elementsRaw = designFilled?.design.elements ?? {};
      rows = Object.entries(elementsRaw).sort((a, b) => {
          return a[0].localeCompare(b[0]);
        }
      ).map(el => {
        const elemName= el[0];
        const elemConfig = el[1];
        const rows = [];
        const availableKeysCSS = [...mandatoryKeys, ...Object.keys(elemConfig.css ?? {})].filter((v,i,a) => a.indexOf(v) === i);

        rows.push(<tr key={`hr-${el[0]}`}><td><hr/></td></tr>)
        rows.push(
          <tr className={styles["table-row"]} key={`name-${el[0]}`}>
            <td>
              {translate('elementName', language)}
            </td>
            <td/>
            <td>
              <RenameField
                path={`design.elements.${elemName}`}
                onChange={setKey}
                readOnly={!getPermissionWrapper(`design.elements.${elemName}.elementType`).write}
                type={getPermissionType(`design.elements.${elemName}.elementType`, 'text')}
                style={!getPermissionWrapper(`design.elements.${elemName}.elementType`).write ? {backgroundColor: colorBG} : {}}
                parentProps={parentProps}
              />
            </td>
            <td>
              <input
                className={styles['entry-copy']}
                type={'button'}
                value={translate('elementCopy', language)}
                onClick={() => {copyElement(el[0])}}
              />
            </td>
            <td>
              <input className={styles['value-input-button']} type={'button'} value={translate('elementDelete', language)} onClick={() => {deleteElement(elemName)}}/>
            </td>
            <td/>
          </tr>
        )
        rows.push(
          <tr className={styles["table-row"]} key={`maintitle-${el[0]}`}>
            <td>
              {translate('elementCaption', language)}
            </td>
            <td/>
            <td>
              <InputString path={`design.elements.${elemName}.maintitle`} parentProps={parentProps}/>
            </td>
            <td/>
            <td/>
            <td/>
          </tr>
        )

        rows.push(
          <tr className={styles["table-row"]} key={`type-${el[0]}`}>
            <td>
              {translate('elementType', language)}
            </td>
            <td/>
            <td>
              <InputDropdown
                path={`design.elements.${elemName}.elementType`}
                valueList={elementTypes}
                readOnly={!getPermissionWrapper(`design.elements.${elemName}.elementType`).write}
                type={getPermissionType(`design.elements.${elemName}.elementType`, 'text')}
                parentProps={parentProps}
              />
            </td>
            <td/>
            <td/>
            <td/>
          </tr>
        )

        rows.push(
          <tr className={styles["table-row"]} key={`useTemplate-${el[0]}`}>
            <td>
              {translate('useTemplate', language)}
            </td>
            <td/>
            <td>
              <InputDropdown
                path={`design.elements.${elemName}.useTemplate`}
                valueList={Object.keys(designs?.templatesElements ?? {})}
                readOnly={!getPermissionWrapper(`design.elements.${elemName}.useTemplate`).write}
                type={getPermissionType(`design.elements.${elemName}.useTemplate`, 'text')}
                parentProps={parentProps}
              />
            </td>
            <td/>
            <td/>
            <td/>
          </tr>
        )

        rows.push(
          <tr className={styles["table-row"]} key={`templateName-${el[0]}`}>
            <td>
              {translate('templateName', language)}
            </td>
            <td/>
            <td>
              <InputString path={`design.elements.${elemName}.templateName`} parentProps={parentProps}/>
            </td>
            <td/>
            <td/>
            <td/>
          </tr>
        )

        rows.push(
          <tr className={styles["table-row"]} key={`move-${el[0]}`}>
            <td>
              {translate('elementMove', language)}
            </td>
            <td/>
            <td>
              <InputDropdown
                path={`design.elements.${elemName}.move`}
                valueList={elementMoves}
                readOnly={!getPermissionWrapper(`design.elements.${elemName}.move`).write}
                type={getPermissionType(`design.elements.${elemName}.move`, 'text')}
                parentProps={parentProps}
              />
            </td>
            <td/>
            <td/>
            <td/>
          </tr>
        )

        getAdditionalKeys(elemConfig.elementType).forEach(({key, ifFilled, values, allowOther, fieldType}) => {
          rows.push(
            (!ifFilled || !(["", undefined, false, 'false'].includes(retrieveKey(elemConfig, ifFilled))))
              ?
              <tr className={styles["table-row"]} key={`${key}-${el[0]}`}>
                <td>
                  {translate(key, language)}
                </td>
                <td/>
                <td>
                  {
                    key === 'goTo' || values
                      ?
                      <InputDropdown
                        path={`design.elements.${elemName}.${key}`}
                        valueList={key === 'goTo' ? ['previousScreen', ...pageNames] : (values ?? [])}
                        allowOther={allowOther}
                        readOnly={!getPermissionWrapper(`design.elements.${elemName}.${key}`).write}
                        type={getPermissionType(`design.elements.${elemName}.${key}`, 'text')}
                        parentProps={parentProps}
                      />
                      :
                      renderSwitchElem(fieldType ?? "string", elemName, key, parentProps)
                  }
                </td>
                <td/>
                <td/>
                <td/>
              </tr>
              : null
          )
        })

        availableKeysCSS.forEach((key) => {
          rows.push(
            <tr className={styles["table-row"]} key={`${key}-${el[0]}`}>
              <td>
                {key}
              </td>
              <td>
                <input className={styles['value-input-button']} type={'button'} value={"Delete"} onClick={() => {deleteKey(elemName, key)}}/>
              </td>
              <td>
                <InputString path={`design.elements.${elemName}.css.${key}`} parentProps={parentProps}/>
              </td>
              <td/>
              <td/>
              <td/>
            </tr>
          )
        })

        rows.push(
          <tr className={styles["table-row"]} key={`newType-${el[0]}`}>
            <td>
              <input
                className={styles['value-input-string']}
                placeholder={translate('elementNewKey', language)}
                id={`newKey-${el[0]}`}
                // value={newKey}
                // onChange={(e) => setNewKey(e.target.value)}
                onKeyDown={(e) => {addNewKeyWrapper(e, elemName)}}
              />
            </td>
            <td>
              <input className={styles['value-input-button']} type={'button'} value={"Add"} onClick={() => {addNewKeyButton(`newKey-${el[0]}`, elemName)}}/>
            </td>
            <td/>
            <td/>
            <td/>
            <td/>
          </tr>
        )


        return rows;
      });

      rows.push([<tr key={`new-hr`}><td><hr/></td></tr>,
        <tr className={styles["table-row"]} key={`new-name`}>
          <td>
            <input
              className={styles['value-input-string']}
              id={`newElement`}
              placeholder={translate('elementNew', language)}
              onKeyDown={(e) => {addNewElementWrapper(e)}}
            />
          </td>
          <td>
            <input className={styles['value-input-button']} type={'button'} value={"Add"} onClick={() => {addNewElementButton(`newElement`)}}/>
          </td>
          <td>
            {
              navigator.userAgent.includes('Firefox')
                ? null
                : <input
                  className={styles['entry-paste']}
                  type={'button'}
                  value={translate('elementPaste', language)}
                  onClick={() => {pasteElement()}}
                />
            }
          </td>
          <td/>
          <td/>
          <td/>
        </tr>
      ])
      break;
    }
    default:
      rows = [];
  }
  return rows;
}
